.cookie-content-background-mobile {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 100vw !important;
    height: 30vh;
    padding: 0px 30px 0px 30px;
    justify-content: center !important;
    min-height: 280px;
}

.cookie-content-button-mobile-en {
    width: 90vw;
    height: 42px;
    padding: 11px 16.4px 12px 16.4px;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #1d3557 !important;
    color: #fff !important;
    display: grid;
    margin: unset !important;
    font-size: 16px;
    font-family: SFThonburi-Regular;
}

.cookie-content-button-mobile-th {
    width: 90vw;
    height: 42px;
    padding: 11px 16.4px 12px 16.4px;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #1d3557 !important;
    color: #fff !important;
    display: grid;
    margin: unset !important;
    font-size: 16px;
    font-family: IBMPlexSansThai-Regular;
}

.cookie-content-setting-mobile-en {
    width: 90vw;
    height: 42px;
    opacity: 1;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    border: solid 0.5px #e5e5e5;
    background-color: #fafafa !important;
    color: #1d3557 !important;
    font-size: 16px;
    margin: unset !important;
    font-family: SFThonburi-Regular;
}

.cookie-content-setting-mobile-th {
    width: 90vw;
    height: 42px;
    opacity: 1;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    border: solid 0.5px #e5e5e5;
    background-color: #fafafa !important;
    color: #1d3557 !important;
    font-size: 16px;
    margin: unset !important;
    font-family: IBMPlexSansThai-Regular;
}

.cookie-content-text-mobile-1-en {
    font-family: SFThonburi-Regular;
    font-size: 22px;
    font-weight: 500;
    color: #65676b;
    margin: unset;
    text-align: center;
    margin-bottom: 20px;
}

.cookie-content-text-mobile-1-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 22px;
    font-weight: 500;
    color: #65676b;
    margin: unset;
    text-align: center;
    margin-bottom: 20px;
}

.cookie-content-text-2-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}

.cookie-content-text-2-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}


.cookie-content-button-en {
    width: 180.9px;
    height: 35px;
    padding: 11px 16.4px 12px 16.4px;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #1d3557 !important;
    color: #fff !important;
    display: grid;
    margin-bottom: unset !important;
    font-family: SFThonburi-Regular;
}

.cookie-content-button-th {
    width: 180.9px;
    height: 35px;
    padding: 11px 16.4px 12px 16.4px;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #1d3557 !important;
    color: #fff !important;
    display: grid;
    margin-bottom: unset !important;
    font-family: IBMPlexSansThai-Regular;
}

.cookie-content-setting-en {
    width: 180.9px;
    height: 42px;
    margin: 6.7px 0 0 47.1px;
    padding: 12px 28.9px 11px 28px;
    opacity: 1;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    border: solid 0.5px #e5e5e5;
    background-color: #fafafa !important;
    color: #1d3557 !important;
    font-size: 16px;
    font-family: SFThonburi-Regular;
}

.cookie-content-setting-th {
    width: 180.9px;
    height: 42px;
    margin: 6.7px 0 0 47.1px;
    padding: 12px 28.9px 11px 28px;
    opacity: 1;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    border: solid 0.5px #e5e5e5;
    background-color: #fafafa !important;
    color: #1d3557 !important;
    font-size: 16px;
    font-family: IBMPlexSansThai-Regular;
}

.cookie-content-text-1-en {
    font-family: SFThonburi-Regular;
    font-size: 32px;
    font-weight: 500;
    color: #65676b;
    margin: unset;
}

.cookie-content-text-1-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 32px;
    font-weight: 500;
    color: #65676b;
    margin: unset;
}

.cookie-content-text-2-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}

.cookie-content-text-2-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}

.cookie-content-text-2-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}

.cookie-content-text-2-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #65676b;
}