.cookie-modal-mobile .modal-dialog {
    margin: unset;
  }
  
  .cookie-modal-mobile h1 {
    font-size: 22px;
    color: var(--main-theme-text-color);
    text-align: center;
    margin-bottom: 30px;
  }
  
  .cookie-modal-mobile .contact-policy-header-en {
    font-family: Montserrat;
    color: #1d3557;
  }
  
  .cookie-modal-mobile .contact-policy-header-th {
    font-family: IBMPlexSansThai-SemiBold;
    color: #1d3557;
    
  }
  
  .cookie-modal-mobile .contact-policy-content {
    /* margin: 30px 0px 0px 0px; */
    max-height: calc(100vh - 10px);
    overflow-y: auto;
    padding-right: 10px;
  }
  
  .cookie-modal-mobile .policy-accept {
    width: 100%;
    height: 42px;
    padding-left: 28.5px;
    padding-right: 28.5px;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #fe6202;
    color: #ffffff;
    border-color: unset;
  }
  
  .cookie-modal-mobile .policy-accept:hover {
    background-color: #fe8512;
  }
  
  .cookie-modal-mobile .policy-accept:disabled {
    height: 42px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #f0f2f5;
    border-color: #f0f2f5;
    color: #c4c4c4;
  }
  
  .cookie-modal-mobile .btn-primary:active:focus {
    box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
  }
  
  .cookie-modal-mobile .policy-accept-en {
    font-size: 16px;
    font-family: SFThonburi-Bold;
  }
  
  .cookie-modal-mobile .policy-accept-th {
    font-size: 16px;
    font-family: IBMPlexSansThai-SemiBold;
  }
  
  .cookie-modal-mobile .modal-content {
    border-radius: unset;
    box-shadow: unset;
    border: unset;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
  }
  
  .cookie-modal-mobile .modal-header {
    border: 0;
    padding: 28px 28px 0 0;
  }
  
  .cookie-modal-mobile .modal-body {
    padding: 0px 25px 25px 25px;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .cookie-modal-mobile .btn-close:focus {
    box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
  }
  
  .cookie-modal-mobile .contact-policy-content-1 {
    color: var(--main-theme-text-color);
  }
  
  .cookie-modal-mobile .contact-policy-content-1-en {
    font-size: 14px;
    font-family: SFThonburi-Regular;
  }
  
  .cookie-modal-mobile .contact-policy-content-1-th {
    font-size: 16px;
    font-family: IBMPlexSansThai-Regular;
  }
  
  .cookie-modal-mobile .contact-policy-content-2 {
    color: var(--main-theme-color);
  }
  
  .cookie-modal-mobile .contact-policy-content-2-en {
    font-family: Montserrat-SemiBold;
    font-size: 16px;
  }
  
  .cookie-modal-mobile .contact-policy-content-2-th {
    font-family: IBMPlexSansThai-Medium;
    font-size: 16px;
  }
  
  .cookie-modal-mobile .contact-policy-content-3 {
    color: var(--main-theme-text-color);
  }
  
  .cookie-modal-mobile .contact-policy-content-3-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    color: #e73845;
  }
  
  .cookie-modal-mobile .contact-policy-content-3-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 16px;
    color: #e73845;
  }
  
  .cookie-modal-mobile .bullets {
    margin-left: 11px;
    margin-right: 11px;
    font-size: 6px;
    color: var(--main-theme-color-hover);
  }
  
  
  
  @media (min-width: 1200px) {
    .cookie-modal-mobile .modal-xl {
      max-width: 850px;
    }
  }
  
  .cookie-modal-button-confirm {
    width: 90%;
    font-size: 14px !important;
    color: #fe6202 !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #fafafa !important;
    border-color: #fafafa !important;
  }
  
  .cookie-modal-button-confirm:focus {
    border-color: #fafafa !important;
    box-shadow: unset !important;
  }
  
  .cookie-modal-button-accept {
    width: 90%;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #fe6202 !important;
    border-color: #fe6202 !important;
  }
  
  .cookie-modal-button-accept:focus {
    border-color: #fe6202 !important;
    box-shadow: unset !important;
  }
  
  .cookie-modal-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .cookie-modal-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .cookie-modal-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .cookie-modal-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked+.cookie-modal-slider {
    background-color: #e73845;
  }
  
  input:focus+.cookie-modal-slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked+.cookie-modal-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .cookie-modal-slider.round {
    border-radius: 34px;
  }
  
  .cookie-modal-slider.round:before {
    border-radius: 50%;
  }
  
  .cookie-modal-switch-setting {
    display: flex;
    justify-content: space-between;
  }
  
  .cookie-modal-scroll::-webkit-scrollbar {
    /* width: 5px; */
    width: 8px !important;
    height: 0px;
  }
  
  .cookie-setting-modal-scroll {
    max-height: 890px;
    margin-top: unset;
    height: calc(100vh - 315px);
    overflow-y: auto;
    padding: 0px 20px 0px 20px;
  }
  
  .cookie-setting-modal-scroll::-webkit-scrollbar {
    /* width: 5px; */
    width: 8px !important;
    height: 0px;
  }