.App {
  text-align: center;
  padding: unset;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.me-auto {
  margin-right: unset !important;
  margin-left: auto !important;
}



.navbar-expand-lg .navbar-nav .nav-link {
  padding: 8px 20px !important;
}

.text-lang-style-checked {
  font-family: SFThonburi-Regular;
  font-size: 16px;
  font-weight: 600;
  color: #e73845 !important;
}

.text-lang-style-unchecked {
  font-family: SFThonburi-Regular;
  font-size: 16px;
  font-weight: normal;
  color: #1d3557 !important;
}

.font-button-en {
  font-family: SFThonburi-Regular;
}

.font-button-th {
  font-family: IBMPlexSansThai-Regular;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}