.navbar-customize {
  padding: 0px 0 10px 0;
}

.navbar-customize>.container-xxl>.navbar-collapse>.navbar-nav>a {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-customize-lang-en {
  font-family: SFThonburi-Regular;
}

.navbar-customize-lang-th {
  font-family: IBMPlexSansThai-Regular;
}

.navbar-customize-lang-fixed-locale {
  font-family: SFThonburi-Regular !important;
}

.navbar-customize-lang-fixed-locale-none {
  color: #c4c4c4 !important;
}

.navbar-customize-fixed {
  background-color: transparent;
}

.navbar-customize-fixed>.container-xl>.navbar-collapse>.navbar-nav>a,
.navbar-customize-fixed>.container-xl>.navbar-collapse>.navbar-nav>.navbar-text {
  position: relative;
  font-size: 16px;
  color: white;
}

/*
  .aaaa:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 25%;
    width: 30px;
    border-bottom: 1px solid var(--main-theme-color);;
  }
  */

.navbar-customize-sticky-fixd-open {
  background-color: white;
}

.navbar-customize-sticky {
  background-color: white;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05); */
  /* opacity: 75%; */
}

.navbar-customize-sticky>.container-xxl>.navbar-collapse>.navbar-nav>a,
.navbar-customize-sticky>.container-xxl>.navbar-collapse>.navbar-nav>.navbar-text {
  color: #65676b;
}

.navbar-brand-none {
  display: none;
}

.navbar-brand {
  width: 110px;
}

.btn-regis-now {
  width: 117px;
  height: 29px;
  border-radius: 8px;
  padding: 0;
}

.btn-regis-now:hover {
  color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  background-color: transparent;
}

.btn-regis-now:active,
.btn-regis-now:focus {
  box-shadow: none !important;
}

.navbar-background-div {
  display: block;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 5%);
  opacity: 0.75;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0px;
}

.sticky-top {
  z-index: 500;
}

@media (max-width: 720px) {
  .navbar-brand {
    display: block;
    width: 72px;
  }
}

.navbar-lang-align {
  display: flex;
  justify-content: center;
  padding-top: calc(100vh - 400px);
}

.navbar-toggle-color .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important
}

.navbar-toggle-color-black .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.active {
  color: #e73845;
  text-decoration: unset !important;
  /* font-weight: 600; */
}

.active:hover {
  color: #e73845;
  text-decoration: unset !important;
  /* font-weight: 600; */
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: #65676b;
}

.navbar-lang-align {
  display: flex;
  justify-content: center;
  padding-top: calc(100vh - 400px);
}

.navbar-toggler:focus {
  outline: white !important;
  box-shadow: none;
}

.navbar-toggler {
  border-color: #ffffff !important;
}