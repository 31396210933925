.send-email-modal h1 {
  font-size: 42px;
  color: #65676b;
  text-align: center;
}

.send-email-modal .contact-form-header-en {
  font-family: Montserrat;
}

.send-email-modal .contact-form-header-th {
  font-family: IBMPlexSansThai-SemiBold;
}

.send-email-modal .contact-form-input-en {
  font-size: 16px;
  font-family: SFThonburi-Regular;
  color: #65676b;
}

.send-email-modal .contact-form-input-th {
  font-size: 16px;
  font-family: IBMPlexSansThai-Regular;
  color: #65676b;
}

.send-email-modal .contact-submit-en {
  font-size: 16px;
  font-family: SFThonburi-SemiBold;
}

.send-email-modal .contact-submit-th {
  font-size: 16px;
  font-family: IBMPlexSansThai-SemiBold;
}

.send-email-modal .contact-invalid-en {
  font-family: SFThonburi-Regular;
}

.send-email-modal .contact-invalid-th {
  font-family: IBMPlexSansThai-Regular;
}

.send-email-modal .send-email-container {
  margin-top: 50px;
}

.send-email-modal .send-email-container input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #fff;
}

.send-email-modal .send-email-container select {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #fff;
}

.send-email-modal .send-email-container textarea {
  width: 100%;
  height: 104px;
  border-radius: 8px;
  box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #fff;
  resize: none;
}

.send-email-modal .send-email-container input::-webkit-input-placeholder {
  /* Edge */
  color: #65676b;
}

.send-email-modal .send-email-container input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #65676b;
}

.send-email-modal .send-email-container input::placeholder {
  color: #65676b;
}

.send-email-modal .form-control:valid,
.send-email-modal .form-control:valid:focus {
  background-image: none;
  border: solid 1px #ececec;
  box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.05);
}

.send-email-modal .form-control:invalid {
  background-image: none;
}

.send-email-modal .form-select:valid {
  border: solid 1px #ececec;
}

.send-email-modal .form-select:valid:focus {
  border: solid 1px #ececec;
  box-shadow: 1px 4px 12px 0 rgba(0, 0, 0, 0.05);
}

.send-email-modal .contact-submit {
  height: 42px;
  padding-left: 28.5px;
  padding-right: 28.5px;
  border-radius: 8px;
  box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: #1d3557;
  color: #ffffff;
  border-color: unset;
}

.send-email-modal .contact-submit:hover {
  background-color: #1d3557;
}

.send-email-modal .contact-submit:disabled {
  background-color: #f0f2f5;
  color: #c4c4c4;
}

.send-email-modal .btn-primary:active:focus {
  box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
}

.send-email-modal textarea::placeholder {
  color: #65676b;
}

.send-email-modal .first-value-select {
  color: #65676b !important;
}

.send-email-modal .first-value-selected {
  color: #65676b;
}


.send-email-modal select option {
  color: #65676b;
}

.send-email-modal .form-select:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}


.send-email-modal .form-select:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

.send-email-modal .form-select:valid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.send-email-modal .form-select:invalid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.send-email-modal .form-check-input {
  width: 16px !important;
  height: 16px !important;
  padding: 3px 2px 2px 3px !important;
  border-radius: 4px !important;
  border: solid 1px #9a9999 !important;
}

.send-email-modal .form-check-input:valid,
.send-email-modal .form-check-input:invalid {
  border: solid 1px #9a9999;
}

.send-email-modal .form-check-input:invalid~.form-check-label {
  color: var(--main-theme-text-color);
}

.send-email-modal .form-check-input:valid~.form-check-label {
  color: var(--main-theme-text-color);
}

.send-email-modal .form-check-input:checked {
  background-color: #fe6202;
  border-color: #fe8512;
}

.send-email-modal .form-check-input:valid:checked {
  background-color: #1d3557;
  border-color: #1d3557;
}

.send-email-modal .form-check-input:focus {
  border-color: #fe8512;
  outline: 0;
  /* box-shadow: 0 0 0 0.25rem rgb(254 133 18 / 25%); */
}

.send-email-modal .form-check-input:valid:focus {
  border-color: #fe8512;
  outline: 0;
  /* box-shadow: 0 0 0 0.25rem rgb(254 133 18 / 25%); */
}

.send-email-modal .contact-policy {
  color: #e73845 !important;
  text-decoration: none;
}

.send-email-modal .contact-policy-en {
  font-size: 16px;
  font-family: SFThonburi-Regular;
  color: #65676b;
}

.send-email-modal .contact-policy-th {
  font-size: 16px;
  font-family: IBMPlexSansThai-Regular;
  color: #65676b;
}

.send-email-modal .modal-content {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border: solid 0.5px #e5e5e5;
  background-color: #fff;
}

.send-email-modal .modal-header {
  border: 0;
  padding: 28px 28px 0 0;
  justify-content: end !important;

}

.send-email-modal .modal-body {
  padding: 0px 60px 40px 60px;
  top: -40px;
}

.send-email-modal .btn-close:focus {
  box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
}

@media (min-width: 1200px) {
  .send-email-modal .modal-xl {
    max-width: 1014px;
  }
}

[hidden] {
  display: none;
}

.img-close-button-1 {
  position: relative;
  left: 180px;
  top: -15px;
}

.img-close-button-2 {
  position: relative;
  top: -45px;
  left: 75px;
  cursor: pointer;
}