
.cookie-policy-modal h1 {
    font-size: 42px;
    color: var(--main-theme-text-color);
    text-align: center;
  }
  
  .cookie-policy-modal .contact-policy-header-en {
    font-family: Montserrat;
  }
  
  .cookie-policy-modal .contact-policy-header-th {
    font-family: IBMPlexSansThai-SemiBold;
  }
  
  .cookie-policy-modal .contact-policy-content {
    margin: 40px 0px 0px 0px;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 10px;
  }
  
  .cookie-policy-modal .policy-accept {
    width: 150px;
    height: 42px;
    padding-left: 28.5px;
    padding-right: 28.5px;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #1d3557;
    color: #ffffff;
    border-color: unset;
  }
  
  .cookie-policy-modal .policy-accept:hover {
    background-color: #1d3557;
  }
  
  .cookie-policy-modal .policy-accept:disabled {
    height: 42px;
    border-radius: 8px;
    box-shadow: 1px 3px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #f0f2f5;
    border-color: #f0f2f5;
    color: #c4c4c4;
  }
  
  .cookie-policy-modal .btn-primary:active:focus {
    box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
  }
  
  .cookie-policy-modal .policy-accept-en {
    font-size: 16px;
    font-family: SFThonburi-Bold;
  }
  
  .cookie-policy-modal .policy-accept-th {
    font-size: 16px;
    font-family: IBMPlexSansThai-SemiBold;
  }
  
  .cookie-policy-modal .modal-content {
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    border: solid 0.5px #e5e5e5;
    background-color: #fff;
    height: 659px;
  }
  
  .cookie-policy-modal .modal-header {
    border: 0;
    padding: 28px 28px 0 0;
    justify-content: end !important;
  }
  
  .cookie-policy-modal .modal-body {
    padding: 0px 55px 40px 55px;
    top: -45px;
  }
  
  .cookie-policy-modal .btn-close:focus {
    box-shadow: 0 0 0 0.1rem rgb(254 133 18 / 25%);
  }
  
  .cookie-policy-modal .contact-policy-content-1 {
    color: var(--main-theme-text-color);
  }
  
  .cookie-policy-modal .contact-policy-content-1-en {
    font-size: 14px;
    font-family: SFThonburi-Regular;
  }
  
  .cookie-policy-modal .contact-policy-content-1-th {
    font-size: 16px;
    font-family: IBMPlexSansThai-Regular;
  }
  
  .cookie-policy-modal .contact-policy-content-2 {
    color: var(--main-theme-color);
  }
  
  .cookie-policy-modal .contact-policy-content-2-en {
    font-family: Montserrat-SemiBold;
    font-size: 20px;
  }
  
  .cookie-policy-modal .contact-policy-content-2-th {
    font-family: IBMPlexSansThai-Medium;
    font-size: 20px;
  }
  
  .cookie-policy-modal .contact-policy-content-3 {
    color: var(--main-theme-text-color);
  }
  
  .cookie-policy-modal .contact-policy-content-3-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
  }
  
  .cookie-policy-modal .contact-policy-content-3-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 16px;
  }
  
  .cookie-policy-modal .bullets {
    margin-left: 11px;
    margin-right: 11px;
    font-size: 6px;
    color: var(--main-theme-color-hover);
  }
  
  
  
  @media (min-width: 1200px) {
    .cookie-policy-modal .modal-xl {
        max-width: 850px;
    }
  }