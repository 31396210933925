.mrg-t-10 {
  margin-top: 10px;
}

.mrg-t-15 {
  margin-top: 15px;
}

.mrg-t-20 {
  margin-top: 20px;
}

.mrg-t-25 {
  margin-top: 25px;
}

.mrg-t-60 {
  margin-top: 60px;
}

.mrg-t-100 {
  margin-top: 100px;
}

.t-a-c {
  text-align: center;
}

.t-a-r {
  text-align: right;
}

.pad-r-15 {
  padding-right: 15px;
}
.footer-customize-lang-en {
  font-family: "SFThonburi-Semibold";
}

.footer-customize-lang-th {
  font-family: "IBMPlexSansThai-SemiBold";
}

.color-fff {
  color: #fff;
}

.color-1d3557 {
  color: #1d3557;
}

.mt-mb-16 {
  margin: 16px 0;
}

.font-title-en2 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.35;
  letter-spacing: normal;
  text-align: left;
}

.font-title-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 20px;
  font-weight: bold;
}

.banner-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-layout {
  display: flex;
}

/* .content-in-card {
} */

.title-card-service {
  font-family: SFThonburi-Regular;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
}

.content-card-service {
  font-family: SFThonburi-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #65676b;
}

.w-240 {
  width: 240px;
}

@media (min-width: 240px) {
}

@media (min-width: 320px) {
  .card-style.card {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #efefef;
    background-color: #fff;
    width: 210px;
    height: 300px;
  }

  .line-title {
    display: flex;
    width: 20px;
    margin-bottom: 10px;
  }

  .w-title {
    width: 250px;
  }

  .font-content-our-service-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-our-service-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .content-list-card {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .hide-cookie-mobile {
    display: unset;
  }

  .footermain {
    display: grid;
    padding-top: 53.5px;
  }

  .footerlink {
    text-decoration: none;
    color: #1d3557;
  }
  .footerlink:hover {
    color: grey;
  }

  .hide-cookie-web {
    display: none;
  }

  .rapidtechzone {
    padding-bottom: 58px;
  }

  .ourservicezone {
    padding-bottom: 83px;
  }

  .footerrapidtechcontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 14px;
    padding-bottom: 58px;
  }

  .footerourservicecontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 14px;
    padding-bottom: 58px;
  }

  .footercontactuscontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 14px;
    padding-bottom: 58px;
  }

  .footerlinkedin {
    margin-right: 35px;
  }

  .footercopyright {
    font-size: 10px;
    color: #1d3557;
    margin-top: 10px;
    margin-bottom: 54px;
  }

  .footersocial {
    padding: 41px 0px 101px 0px;
  }

  .footertopicname {
    font-weight: bold;
    color: #1d3557;
    font-size: 18px;
    padding: 0px 0px 20px 0px;
  }

  .w-320 {
    width: 250px;
  }

  .layout-size-image-white {
    overflow-x: hidden;
  }

  .icon-social {
    display: flex;
    width: 43px;
  }

  .pd-card-social.card-body {
    padding: 7px 15px;
  }

  .card {
    width: 76px;
    height: 59px;
  }

  nav .navbar-collapse {
    position: fixed;
    top: 56px;
    background: white;
    right: -100%;
    width: 100vw;
    height: 100vh;
    display: block;
    transition: right 0.008s ease;
  }

  nav .navbar-collapse.show {
    right: 0;
  }

  .navbar-hidden-row {
    display: none !important;
  }

  .display-mail-w {
    display: none;
  }

  .display-mail-m {
    display: unset;
  }
  .container-xl {
    max-width: 100%;
    width: 100%;
    padding: unset;
  }

  .size-image-white {
    max-width: 130px;
    width: 100%;
    margin-left: -10px;
    margin-top: -40px;
  }

  .size-image-red {
    max-width: 170px;
    margin-right: -40px;
    margin-top: -7px;
  }

  /* .size-image-red {
    max-width: 170px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    left: 50px;
    top: -5px;
    z-index: -1;
  } */

  .content-list-card {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .container-image {
    position: relative;
    text-align: center;
    color: white;
  }

  .container-image-centered {
    width: 100%;
    height: 610px;
    object-fit: cover;
  }

  .centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 236px;
  }

  .container-layout-card-contact {
    text-align: -webkit-center;
  }

  .layout-card-contact {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 36px;
    max-width: 240px;
  }

  .padding-content-home {
    padding: 40px 40px 60px;
    max-width: 340px;
  }

  .font-title-en {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  .font-title-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
  }

  .padding-content {
    padding: 80px 40px;
  }
}

@media (min-width: 480px) {
  .container-xl {
    max-width: 100%;
  }
}

@media (min-width: 600px) {
  .container-xl {
    max-width: 100%;
  }

  .content-list-card {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .container-xl {
    max-width: 100%;
  }

  .size-image-white {
    max-width: 200px;
    width: 100%;
    max-height: 350px;
    margin-left: -65px;
    margin-top: -20px;
  }

  .size-image-red {
    max-width: 350px;
    width: 100%;
    margin-right: -40px;
    margin-top: 35px;
    position: unset;
    right: unset;
    top: unset;
    z-index: unset;
  }

  .padding-content-home {
    padding: 40px 60px 60px;
    max-width: 391px;
  }

  .margin-content-home {
    margin-top: -320px;
  }

  .padding-content {
    padding: 80px 80px;
  }

  .content-list-card {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .size-image-white {
    max-width: 260px;
    width: 100%;
    max-height: 350px;
    margin-left: -65px;
    margin-top: -20px;
  }

  .size-image-red {
    max-width: 430px;
    width: 100%;
    margin-right: -40px;
    margin-top: 35px;
    position: unset;
    right: unset;
    top: unset;
    z-index: unset;
  }
}

@media (min-width: 961px) {
  .container-xl {
    max-width: 100%;
    width: 100%;
    padding: unset;
  }

  .size-image-white {
    max-width: 260px;
    width: 100%;
    max-height: 350px;
    margin-left: -65px;
    margin-top: -20px;
  }

  .size-image-red {
    max-width: 430px;
    width: 100%;
    margin-right: -40px;
    margin-top: 35px;
    position: unset;
    right: unset;
    top: unset;
    z-index: unset;
  }
}

@media (min-width: 1024px) {
  .line-title {
    display: flex;
    width: 30px;
    margin-bottom: 10px;
  }
  .container-xl {
    max-width: 1024px;
    width: 100%;
    padding: unset;
  }

  .size-image-white {
    max-width: 260px;
    width: 100%;
    max-height: 350px;
    margin-left: -65px;
    margin-top: -20px;
  }

  .size-image-red {
    max-width: 430px;
    width: 100%;
    margin-right: -40px;
    margin-top: 35px;
    position: unset;
    right: unset;
    top: unset;
    z-index: unset;
  }

  /* .container-xl {
    max-width: 980px;
  } */

  .hide-cookie-mobile {
    display: none;
  }

  .container-layout-card-contact {
    text-align: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .div-layout-card-contact {
    width: 50%;
  }

  .layout-card-contact {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0px;
    max-width: 100%;
    align-items: flex-end;
    margin-bottom: 16px;
  }

  .mrg-rl-10 {
    margin: 0px 10px;
  }

  .content-list-card {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .padding-content-home {
    padding: 40px 80px 60px;
    max-width: 605px;
  }

  .margin-content-home {
    margin-top: -320px;
  }

  .font-title-en {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  .font-title-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-en {
    font-family: SFThonburi-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-our-service-en {
    font-family: SFThonburi-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .font-content-our-service-th {
    font-family: IBMPlexSansThai-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .container-image-centered {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    max-width: 455px;
  }

  .footermain {
    display: flex;
    padding-top: 65px;
  }

  .footerrapidtechzone {
    text-align: left;
    margin-left: 170px;
  }

  .footerourservicezone {
    text-align: left;
    margin-left: 180px;
  }

  .footercontactuszone {
    text-align: left;
    margin-left: 130px;
  }

  .footerrapidtechcontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
  }

  .footerourservicecontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footercontactuscontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footerlinkedin {
    margin-right: 25px;
  }

  .footersocial {
    padding: 22px 0px 10px 0px;
  }

  .footercopyright {
    font-size: 14px;
    color: #1d3557;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .footertopicname {
    font-weight: bold;
    color: #1d3557;
    font-size: 18px;
    margin: 0px 0px 16px 0px;
  }

  .w-320 {
    width: 385px;
  }

  .w-title {
    width: 310px;
  }

  .layout-size-image-white {
    overflow-x: unset;
  }

  .icon-social {
    display: flex;
    width: 70px;
  }

  .pd-card-social .card-body {
    padding: 7px 15px;
  }

  nav .navbar-collapse {
    position: unset;
    top: unset;
    background: unset;
    right: unset;
    width: unset;
    height: unset;
    display: unset;
    transition: unset;
  }

  nav .navbar-collapse.show {
    right: unset;
  }

  .navbar-hidden-burger {
    display: none;
  }

  .navbar-hidden-row {
    display: flex !important;
  }

  .display-mail-w {
    display: unset;
  }

  .display-mail-m {
    display: none;
  }

  .hide-cookie-mobile {
    display: none;
  }

  .hide-cookie-web {
    display: unset;
  }

  .padding-content {
    padding: 80px 80px;
  }

  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 835px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1100px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 795px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px;
  }

  .footermain {
    display: flex;
    padding-top: 65px;
  }

  .footerrapidtechzone {
    text-align: left;
    margin-left: 180px;
  }

  .footerourservicezone {
    text-align: left;
    margin-left: 240px;
  }

  .footercontactuszone {
    text-align: left;
    margin-left: 160px;
  }

  .footerrapidtechcontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
  }

  .footerourservicecontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footercontactuscontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footerlinkedin {
    margin-right: 25px;
  }

  .footersocial {
    padding: 22px 0px 10px 0px;
  }

  .footercopyright {
    font-size: 16px;
    color: #1d3557;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .footertopicname {
    font-weight: bold;
    color: #1d3557;
    font-size: 18px;
    margin: 0px 0px 16px 0px;
  }

  .padding-content {
    padding: 80px 120px;
  }

  .padding-content-home {
    padding: 40px 120px 60px;
    max-width: 690px;
  }

  .margin-content-home {
    margin-top: -320px;
  }

  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 760px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1300px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 715px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1400px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 650px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }

  .card-style.card {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #efefef;
    background-color: #fff;
    width: 230px;
    height: 300px;
  }
}

@media (min-width: 1500px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 610px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1600px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 610px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1700px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 550px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1800px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 440px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1900px) {
  .cookie-content-background {
    background-color: #fff !important;
    opacity: 0.9;
    border-radius: 10px;
    box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
    border: solid 1px #ececec;
    width: 939px !important;
    height: 142px;
    padding: 0px 30px 0px 47px;
    /* left: calc(100vh - 480px) !important; */
    bottom: 5vh !important;
    left: 50% !important;
    transform: translate(-50%);
  }
}

@media (min-width: 1400px) {
  .container-xl {
    max-width: 1320px;
  }
  .footermain {
    display: flex;
    padding-top: 65px;
  }

  .footerrapidtechzone {
    text-align: left;
    margin-left: 94px;
  }

  .footerourservicezone {
    text-align: left;
    margin-left: 150px;
  }

  .footercontactuszone {
    text-align: left;
    margin-left: 150px;
  }

  .footerprivacyzone {
    text-align: left;
    margin-left: 150px;
    padding-right: 94px;
  }

  .footerrapidtechcontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
  }

  .footerourservicecontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footercontactuscontent {
    line-height: 30px;
    color: #1d3557;
    font-size: 16px;
    padding-bottom: 58px;
  }

  .footerlinkedin {
    margin-right: 25px;
  }

  .footersocial {
    padding: 22px 0px 10px 0px;
  }

  .footercopyright {
    font-size: 14px;
    color: #1d3557;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .footertopicname {
    font-weight: bold;
    color: #1d3557;
    font-size: 18px;
    margin: 0px 0px 16px 0px;
  }
}

.color-fff {
  color: #fff;
}

.color-1d3557 {
  color: #1d3557;
}

@media (min-width: 480px) {
}

@media (min-width: 961px) {
}

@media (min-width: 1024px) {
  .hide-cookie-mobile {
    display: none;
  }

  .hide-cookie-web {
    display: unset;
  }

  .padding-content {
    padding: 80px 80px;
  }
}

@media (min-width: 1200px) {
  .padding-content {
    padding: 80px 120px;
  }
  .padding-content-home {
    padding: 40px 120px 60px;
    max-width: 690px;
  }

  .margin-content-home {
    margin-top: -320px;
  }
}

.mt-mb-16 {
  margin: 16px 0;
}

.card-style-social.card {
  width: auto;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #efefef;
  background-color: #fff;
}

.font-title-en2 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.35;
  letter-spacing: normal;
  text-align: left;
}

/* .font-title-th {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
} */

.banner-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-layout {
  display: flex;
}

/* .content-in-card {
} */

.title-card-service-en {
  font-family: SFThonburi-Regular;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
  width: 125px;
}

.title-card-service-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
  width: 125px;
}

.content-card-service-en {
  font-family: SFThonburi-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #65676b;
}

.content-card-service-th {
  font-family: IBMPlexSansThai-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #65676b;
}

.w-240 {
  width: 240px;
}

.pdt-20 {
  padding-top: 16px;
}

.pdt-cookie {
  padding-top: 16px;
}
